import styled from "styled-components"
import { devices } from "src/theme/device"
import { Link } from "@reach/router"

export default styled(Link)`
    font-size: 16px;
    color: ${({ theme }) => theme.colors.primaryDark};
    margin: auto 20px;
    background: none;
    border: none;
    display: inline-block;
    position: relative;
    cursor: pointer;
    :after {
        display: block;
        content: "";
        padding-top: 3px;
        border-bottom: solid 2px ${({ theme }) => theme.colors.primary};
        transform: scaleX(0);
        transition: transform 250ms ease-in-out;
        transform-origin: 100% 50%;
    }
    :hover:after {
        transform: scaleX(1);
        transform-origin: 0 50%;
    }

    ${devices.tablet} {
        margin: auto 0;
    }
`
